import React from "react"
import OmniAural from "omniaural"
import { Themes } from "../managers/ThemeManager"
import BulletList from "./screen-components/BulletList"

const LandingPage = ({ history, cookies }) => {
  const images = Themes.currentTheme().images

  const checkAuthAndRedirect = () => {
    const defaultFactor = OmniAural.state?.config?.default_registration_factor?.value()

    let registrationRoute = "/register_pfa"

    if (defaultFactor === "second") {
      registrationRoute = "/register"
    }

    history.push(registrationRoute)
  }

  const createBulletListItems = (strIn) => {
    const arrOut = []
    Object.keys(Themes.currentTheme().strings).forEach((entry) => {
      if (entry.includes(strIn) && Themes.currentTheme().strings[entry].length !== 0) {
        arrOut.push(Themes.currentTheme().strings[entry])
      }
    })
    return arrOut
  }

  return (
    <div className="landing-page-container">
      <div className="header-body" style={{ backgroundImage: `url(${images.headerBackground})` }}>
        <img src={images.mobileConnectLogo} className="header-image" alt="mobile connect logo" />
      </div>
      <img src={images.appHeroImage} className="app-hero-image" alt="app hero" />
      <div className="body-container">
        <div className="body-container-head">
          <h5 className="bold">
            {Themes.currentTheme().strings.registration_greeting}
          </h5>
        </div>
        <div className="body-container-body">
          <BulletList items={createBulletListItems("register_instructions")} />
        </div>
        <div className="body-container-register">
          <button type="button" onClick={checkAuthAndRedirect} className="register-button">
            <h5>REGISTER</h5>
          </button>
          <div className="register-addl-text">
            <p>Already registered?</p>
            <p className="text-link" onClick={checkAuthAndRedirect}>
              Log in to manage your account
            </p>
          </div>
          <div className="register-addl-text">
            <p>Have Questions?</p>
            <a className="text-link" href="/faq">Click Here</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage
