import React, { Component } from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import Login from "./screens/Login"
import Register from "./screens/Register"
import SecureContent from "./screens/SecureContent"
import TermsPage from "./screens/TermsPage"
import Challenge from "./screens/Challenge"
import LandingPage from "./screens/LandingPage"
import AppDownload from "./screens/AppDownload"
import LoadingSpinner from "./components/LoadingSpinner"
import { withCookies } from "react-cookie"
import initialState from "./state/initialState.json"
import OmniAural from "omniaural"
import { getConfig } from "./services"
import { Themes } from "./managers/ThemeManager"
import Logout from "./screens/Logout"
import Registered from "./screens/Registered"
import RegisterPFA from "./screens/RegisterPFA"
import AssertionRenderer from "./components/AssertionRenderer"
import CreateUser from "./screens/CreateUser"
import RegisteredPFA from "./screens/RegisteredPFA"
import PageNotFound from "./screens/PageNotFound"
import { Constants } from "./Constants"
// import VideoPage from "./screens/VideoPage"
// import VideoPageSingular from "./screens/VideoPageSingular"
import SponsoredRegisterPFA from "./screens/SponsoredRegisterPFA"
import SponsoredRegisteredPFA from "./screens/SponsoredRegisteredPFA"
import FAQPage from "./screens/FAQ"

// dashboard screens
import Dashboard from "./dashboard/Dashboard"

import "./App.css"
import "./state/actions"
import ChallengePFA from "./screens/ChallengePFA"
import ErrorBoundary from "./components/ErrorBoundary"

OmniAural.initGlobalState(initialState)

class App extends Component {
  state = {
    appReady: false
  }

  componentDidMount() {
    getConfig()
      .then((config) => {
        OmniAural.state.config.set(config)

        if (config?.theme) {
          Themes.setCurrentTheme(config.theme)
        }

        if(config?.theme) {
          document.getElementById('favicon').setAttribute('href','favicon' + config.theme + '.ico')
        }

        this.setState({ appReady: true })
      })
      .catch(() => {
        this.setState({ appReady: true })
      })
  }

  renderDevPaths = () => {
    if (Constants.IS_DEV) {
      let routes = [
        <Route key={0} exact={true} path={"/app/download"} component={AppDownload} />,
        <Route
          key={3}
          exact={true}
          path={"/secureContent"}
          render={(props) => <SecureContent {...props} cookies={this.props.cookies} />}
        />,
        <Route key={5} exact={false} path="/createUser" component={CreateUser} />,
        <Route
          key={6}
          exact={true}
          path={"/logout"}
          component={(props) => <Logout {...props} cookies={this.props.cookies} />}
        />
      ]
      return routes
    }
  }

  render() {
    const colors = Themes.currentTheme().colors
    let root = document.querySelector(":root")
    for (var cssVariable in colors) {
      root.style.setProperty(`--${cssVariable}`, `${colors[cssVariable]}`)
    }

    if (!this.state.appReady) {
      return <LoadingSpinner startup />
    }

    return (
      <ErrorBoundary>
        <div>
          <Switch>
            <Route
              exact={true}
              path={"/"}
              render={(props) => <LandingPage {...props} cookies={this.props.cookies} />}
            />
            <Route
              exact={true}
              path={"/login"}
              render={(props) => {
                if (Constants.IS_DEV) {
                  return <Login {...props} cookies={this.props.cookies} />
                } else {
                  const federationUrl = OmniAural.state.config?.authn_url?.value()
                  const forwardPath = props.history?.location?.state?.forwardPath || "/register"

                  //Construct mobileconnect url to navigate user on login success
                  const forwardTo = `${window.location.protocol}//${window.location.hostname}${forwardPath}`

                  window.location.assign(`${federationUrl}?u=${encodeURIComponent(forwardTo)}`)
                }
              }}
            />
            <Route
              exact={true}
              path={"/process_assertion"}
              render={(props) => {
                if (Constants.IS_DEV && props.location?.state?.forwardPath) {
                  return <Redirect to={props.location.state.forwardPath} />
                } else {
                  return <AssertionRenderer {...props} />
                }
              }}
            />
            <Route
              exact={true}
              path={"/successfully_registered"}
              render={(props) => <Registered {...props} cookies={this.props.cookies} />}
            />
            <Route
              exact={true}
              path={"/successfully_registered_pfa"}
              render={(props) => <RegisteredPFA {...props} cookies={this.props.cookies} />}
            />
            <Route
              exact={true}
              path={"/register"}
              render={(props) => <Register {...props} cookies={this.props.cookies} />}
            />
            <Route
              exact={true}
              path={"/register_pfa"}
              render={(props) => <RegisterPFA {...props} cookies={this.props.cookies} />}
            />
            <Route
              exact={true}
              path={"/spfa_register"}
              render={(props) => <SponsoredRegisterPFA {...props} cookies={this.props.cookies} />}
            />
            <Route
              exact={true}
              path={"/spfa_registered"}
              render={(props) => <SponsoredRegisteredPFA {...props} cookies={this.props.cookies} />}
            />
            <Route
              exact={true}
              path="/admin_dashboard"
              render={(props) => <Dashboard {...props} cookies={this.props.cookies} />}
            />
            ,
            {/* <Route exact={true} path={"/videos"} render={(props) => <VideoPage {...props} />} />
            <Route
              exact={true}
              path={"/videosingular"}
              render={(props) => <VideoPageSingular {...props} />}
            /> */}
            <Route exact={true} path={"/terms"} component={TermsPage} />
            <Route exact={true} path={"/faq"} component={FAQPage} />
            <Route
              exact={true}
              path={"/challenge"}
              render={(props) => <Challenge {...props} cookies={this.props.cookies} />}
            />
            <Route
              exact={true}
              path={"/challenge_pfa"}
              render={(props) => <ChallengePFA {...props} cookies={this.props.cookies} />}
            />
            {this.renderDevPaths()}
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </ErrorBoundary>
    )
  }
}

export default withCookies(App)
