import React, { Component } from "react"
import { Themes } from "../managers/ThemeManager"

class PageNotFound extends Component {
  render() {
    console.log("Page Not Found...")
    const images = Themes.currentTheme().images
    return (
      <div>
        <div className="header-body" style={{ backgroundImage: `url(${images.headerBackground})` }}>
          <img src={images.mobileConnectLogo} className="header-image" alt="mobile connect logo" />
        </div>
        <div className="body-container">
          <div className="body-container-body">
            <div className="not-found">
              <h1>Page Not Found</h1>
              <p>The page you are looking for does not exist.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PageNotFound
